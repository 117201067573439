import {OPEN_SUBSCRIBE_MODAL} from '../constants';

export default function openSubscribedModal(state = [], action) {
    switch (action.type) {
        case OPEN_SUBSCRIBE_MODAL:
            return {
                ...state,
                subscribedModalRef: action.data
            }
        default:
            return state
    }
}