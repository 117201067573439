import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/challenges.css';
import { requestPostHTTP } from "../helpers/helper";
import { useNavigate, useParams } from "react-router-dom";
import noImage from "../../assets/images/cards/no-image.jpg";
import Loader from "../includes/Loader";
import rewardsImg from "../../assets/images/icons/rewards.png";


function SubmitEntry() {
    let navigate = useNavigate();
    const { game_id } = useParams();
    const { challenge_id } = useParams();
    const { entry_status } = useParams();
    const [submitEntry, setSubmitEntry] = useState([]);
    const [notFoundSubmitEntry, setNotSubmitEntry] = useState(false);
    const [imageSrcTemp, setImageSrcTemp] = useState('');
    const [imageSuccessMsg, setImageSuccessMsg] = useState(false);
    const [imageSrcMsg, setImageSrcMsg] = useState(false);
    const [loading, setLoading] = useState(true);
    const entryDetailImgRef = useRef(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        async function fetchReqData() {
            try {
                const getSubmitEntry = await requestPostHTTP('/v1/challenge/details', {
                    gameId: game_id,
                    challengeId: challenge_id
                });

                if (getSubmitEntry.data) {
                    setSubmitEntry(getSubmitEntry.data);
                } else {
                    setNotSubmitEntry(true);
                }
            } catch (error) {
                console.error('Error fetching game challenges submit entry data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchReqData();
    }, []);

    /**
     * Post the Entry Image
     */
    function postSubmitEntry(image) {
        if (image) {
            try {
                requestPostHTTP('/v1/challenge/entry', {
                    entryImage: image,
                    gameId: game_id,
                    challengeId: challenge_id
                }).then(response => {
                    if (response.data.makeEntry > 0) {
                        setImageSuccessMsg('Your challenge entry has been submitted successfully.\nThe A.I system will check if your entry is legitimate.\nYou will be awarded credits if entry is legitimate else it will be discarded.\nPlease check back later.');
                        setShowSuccessModal(true);
                        setTimeout(() => {
                            navigate("/challenges");
                        }, 4000);
                    } else {
                        setImageSrcMsg('Please try again');
                    }
                })
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    }

    /**
     *
     * @param e
     */
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            const fsize = e.target.files[0].size;
            reader.onloadend = () => {
                if (fsize <= 2048000) {
                    setImageSrcMsg(false);
                    setImageSrcTemp(reader.result);
                    postSubmitEntry(reader.result);
                } else {
                    setImageSrcTemp(null);
                    setImageSrcMsg('Image should be less then 2mb');
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <section>
            {loading ? (<Loader />) : (
                <div className="cards-wrapper-tournament gaming-challenges col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="tournament-heading">
                        <h2 className="headingz mb-4">Daily Gaming Tasks</h2>
                        <p className="submit-entry-subheading">Complete the task, Submit entry and get rewards</p>
                    </div>
                    <div className="row submit-entry-wrapper">
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                            <p className="game-heading">{submitEntry.game} Tasks</p>
                            <div className="cards-tournament-round">
                                <div className="card-image-wrapper">
                                    <img src={`data:${submitEntry.mimeType};base64, ${submitEntry.challengeThumbnail}`}
                                        alt="card" />
                                </div>
                                <div className="cards-info submit-entry-card">
                                    <div className="row">
                                        <div className="col-6 card-heading-col">
                                            <h6 className="tournament-info-heading">{submitEntry.objective}</h6>
                                            <p className="card-heading-tournament">Win {submitEntry.prize} Credits</p>
                                        </div>
                                        <div className="col-6 your-credit">
                                            <p className="your-credit-challeneg">Your Credit</p>
                                            <p className="your-credit-challeneg-count">{submitEntry.credits}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-1 col-lg-1 arrow-steps-tournment">
                            <p className="arrow-steps-tournment-p">>></p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="take-scewwnshot">Take a screenshot of your game and submit here
                                {entry_status === 2 ? ('Your previous entry was rejected try submitting again') : ''}
                            </p>
                            <img className="submit-entry-img" src={imageSrcTemp ? imageSrcTemp : noImage}
                                alt="Uploaded" />
                            <p className="choose-file-text">File should be PNG/JPG/JPEG of max size 2MB*</p>
                            {imageSrcMsg && <p className="choose-file-text text-red">{imageSrcMsg}</p>}
                            <div className="choose-a-file">
                                <label htmlFor="fileInput" className="file-input-button">Choose File</label>
                                <input type="file" onChange={handleImageChange} ref={entryDetailImgRef} id="fileInput"
                                    accept="image/png, image/jpeg, image/jpg" name="entryImage"
                                    className="file-input" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {notFoundSubmitEntry && (
                <div className="d-flex justify-content-center align-items-center no-data-found">
                    <p>No submit entry found</p>
                </div>
            )}

            {/*Model*/}
            {showSuccessModal &&
                <div className="modal subscribe-modal" id="showSuccess" tabIndex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
                    <div className="modal-dialog subscribe-modal-dialog">
                        <div className="modal-content subscribe-modal-content">
                            <div className="modal-body subscribe-modal-body">
                                <div className="subscribe-modal-body-back-img notification-back-img">
                                    <img className="rewards-modal-icon" src={rewardsImg} alt="rewards-success" />
                                </div>
                                <h6 className="subscribe-info-heading-modal">Congratulations</h6>
                                <p className="subscribe-heading-modal">{imageSuccessMsg}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </section>

    );
}

export default SubmitEntry;