import React, { useEffect, useState } from 'react';
import '../../assets/css/modal.css';

const VideoModal = ({ videoUrl, onClose }) => {
    const [loading, setLoading] = useState(true);
    const embedCode = `<iframe width="100%" height="315" src="${videoUrl.replace('watch?v=', 'embed/')}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    return (
        <div className="modal-container pusher-notify-wrapper pusher-notification-modal">
            <div className="modal-content pusher-notification bg-dark">
                {/* <video controls>
                    <source src={videoUrl} type="video/mp4" />
                </video> */}
                <div dangerouslySetInnerHTML={{ __html: embedCode }} />
                <button className="notify-close-btn" onClick={onClose}>
                    &times;
                </button>
            </div>
        </div>
    );
};

export default VideoModal;
