import React, { useState, useEffect } from 'react';
import '../../assets/css/card-slider.css';
import arrowImg from '../../assets/images/icons/errow.png';
import Slider from 'react-slick';
import Loader from "../includes/Loader";
import VideoModal from "../modals/VideoModal";
import { getYoutubeVideos } from '../api_calls/getYoutubeVideos';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const YouTubeVideos = (props) => {
    const [videosList, setVideosList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [youtubeVideoUrl, setYoutubeVideoUrl] = useState(null);

    useEffect(() => {
        async function getChannelVideos() {

            try {
                const response = await getYoutubeVideos();
                const videoLinks = response.data.data
                    // Filter out items with id.channelId
                    // .filter(item => item.id.videoId)
                    // Map remaining items to videoLinks array
                    .map(data => ({
                        id: data.id.videoId,
                        title: data.title,
                        thumbnail: data.thumbnail,
                        link: data.link,
                    }));


                setVideosList(videoLinks);
            } catch (error) {
                setVideosList([]);
                console.error('Error fetching channel videos:', error);
            } finally {
                setLoading(false);
            }
        }

        getChannelVideos();


    }, []);

    const windowWidth = window.innerWidth;
    let slidesToShow = 3.999;
    if (windowWidth > 850 && windowWidth <= 900) {
        slidesToShow = 2.999;
    } else if (windowWidth <= 850) {
        slidesToShow = 1;
    }
    // const settings = {
    //     dots: false,
    //     infinite: videosList.length > 4,
    //     speed: 500,
    //     slidesToShow: slidesToShow,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 10000,
    // };

    function openVideoModal(videoUrl) {
        setYoutubeVideoUrl(videoUrl);
        setIsVideoModalOpen(true);
    }

    return (
        <section className='section-card'>
            <div className="mt-4 cards-wrapper card-slider-wrapper gaming-tasks-slider-wrapper mb-5" style={{ paddingLeft: "5px" }}>
                <div className="card-slider-heading">
                    <h2 className="headingz mb-4">Tournament Highlights
                        <span className="errow-icon">
                            <img src={arrowImg} alt="errow-img" />
                        </span>
                    </h2>
                    <p className="main-slider-heading">Watch the players battle to take the crown</p>
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                        loop={true}
                        // centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            270: { // when window width is >= 320px (mobile devices)
                                slidesPerView: 1,
                            },
                            320: {
                                slidesPerView: 1.3,
                            },
                            331: {
                                slidesPerView: 1.4,
                            },
                            345: {
                                slidesPerView: 1.45,
                            },
                            360: {
                                slidesPerView: 1.5,
                            },
                            370: {
                                slidesPerView: 1.55,
                            },
                            380: {
                                slidesPerView: 1.6,
                            },
                            401: {
                                slidesPerView: 1.65,
                            },
                            420: {
                                slidesPerView: 1.7,
                            },
                            440: {
                                slidesPerView: 1.8,
                            },
                            465: {
                                slidesPerView: 1.9,
                            },
                            489: {
                                slidesPerView: 1.99,
                            },
                            511: {
                                slidesPerView: 2.1,
                            },
                            560: {
                                slidesPerView: 2.3,
                            },
                            590: {
                                slidesPerView: 2.4,
                            },
                            621: {
                                slidesPerView: 2.5,
                            },
                            640: {
                                slidesPerView: 2.6,
                            },
                            701: {
                                slidesPerView: 2.9,
                            },
                            777: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.2,
                            },
                            870: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.5,
                            },
                            941: { // when window width is >= 768px (tablets)
                                slidesPerView: 3.7,
                            },
                            1024: { // when window width is >= 1024px (desktops)
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Autoplay]}

                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        {videosList.length > 0 && videosList.map((video, index) => (
                            <SwiperSlide style={{ marginLeft: "15px" }} key={`${video.id}-${index}`}>
                                <div onClick={() => openVideoModal(video.link)}>
                                    <div className="cards-round">
                                        <img className="card-img" src={video.thumbnail} alt="Card-img" />
                                        <div className="cards-info">
                                            <h3 className="cards-label text-white">
                                                {video.title}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}

                {videosList.length <= 0 && (
                    <div className="d-flex justify-content-center align-items-center no-data-found">
                        <p>No video found</p>
                    </div>
                )}

                {isVideoModalOpen && (
                    <VideoModal videoUrl={youtubeVideoUrl} onClose={() => setIsVideoModalOpen(false)} />
                )}
            </div>
        </section >
    );
}

export default YouTubeVideos;
